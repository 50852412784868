<template>
  <DefaultSelect
    :label="label"
    :placeholder="placeholder"
    :value.sync="selected"
    :items="appointments"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    required
    returnObject
  ></DefaultSelect>
</template>

<script>
import CRUDMixin from "@/mixins/CRUDMixin";
import PatientService from "@/services/patient.service";

export default {
  mixins: [CRUDMixin],
  props: {
    value: {},
    patient: {},
    session: {},
    label: {},
    placeholder: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      appointments: [],
    };
  },
  components: {
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  methods: {
    fetch() {
      this.beforeRequest();
      PatientService.savedSessionAppointmentList(
        this.patient, 
        this.session)
        .then((reply) => {
          this.appointments = reply;
          if (this.appointments.length > 0) {
              this.selected = this.appointments[0];
          }
          this.reset();
        })
        .catch((error) => {
          console.log("ERROR", error);
          this.handleError("Coś poszło nie tak.");
        });
    },
    itemText(item) {
      return `Wizyta ${item.order}, ${item.date_time}`;
    },
    itemValue(item) {
      return item.id;
    },
  },
  created() {
    this.fetch();
  },
};
</script>